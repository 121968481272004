/* ====================== RESPONSIVE SETTINGS ====================== */


/* =========== All page Responsive =============== */
/* ======= min-width @media Query Start ========== */

@media all and (min-width: 1400px) {

    /* ======== index.html ======== */
    .hero-facility {
        right: 4%;
        top: 12%;
    }
    .hero-image1 {
        left: 11%;
    }
    .bg-circle {
      display: none;
    }
    .hero-circle {
        margin-left: 70px;
    }
    .amenities-p {
        padding: 25px 180px;
    }
    .Amenities h2 {
        padding: 0px 250px;
    }
    .workspaces .col-lg-6:nth-child(2){
        padding-right: 50px;
    }
    .spaces-circle1 {
        top: -17%;
        right: -3%;
    } 
    .spaces-circle2 {
        bottom: -18%;
        left: 29%;
        width: 400px;
        height: 400px;
    } 
    .spaces-p{
        padding: 15px 280px 25px 280px;
    }
    .spaces h2{
        padding: 0px 220px;
    }
    .find h2 {
        padding: 0px 68px 0px 0px;
    }
    .find-p {
        padding: 20px 47px 30px 0px;
    }
    .plan .row{
        position: unset;
    }
    .plan-circle{
        display: none;
    }
    .gallery-p {
        padding: 20px 285px;
    }
    .plan-p {
        padding: 20px 260px;
    }
    .gallery {
        padding-top: 100px;
    }
    .gallery h2{
        padding: 0px 200px;
    }
    .Companies img {
        width: 92%;
        position: unset;
    }
    .Companies .col-lg-6{
       justify-content: center;
    }
    .companies-circle1 {
        width: 700px;
        height: 700px;
    }
    .companies-circle {
        top: -45%;
        left: 2%;
    }
    .slider_customers .slick-slide {
        padding: 0px 47px;
    }
    .customers-main-p {
        padding: 0px 300px 30px 300px;
    }
    .question h2 {
        padding: 0px 64px 0px 0px;
    }
    .question p {
        padding: 0px 53px 0px 0px;
    }
    .news-p {
        padding: 15px 280px 25px 280px;
    }
    .news h2{
        padding: 0px 280px 0px 280px;
     }
     .Subscribe-us h2 {
        padding: 0px 310px 25px 310px;
    }
    .Subscribe input {
        width: 80%;
    }
    .banner .Subscribe input {
        width: 72%;
    }
    /* ======== about.html ======== */
    .about-circle {
        margin-left: 150px;
    }
}

@media all and (min-width: 991px){
    /* ======== index.html ======== */
    .find .col-md-5 {
        width: 44%;
    }
}
/* ======= min-width @media Query End ========== */

/* ======= max-width @media Query Start ========== */
@media all and (max-width: 1400px){
    /* ======== index.html ======== */
    .Amenities .row .col-lg-3{
        width: 23%;
    }
    .Amenities .row{
        gap: 20px;
    }
}

@media all and (max-width: 1024px){
    /* ======== index.html ======== */
    .Amenities h2 {
         padding: 0px 100px;
    }
    .amenities-p{
        padding: 20px 100px;
    }
    .spaces-circle1 {
        right: -2%;
    }
    .plan .plan-link i {
        padding-right: 12px;
    }
    .plan .plan-card {
        padding: 25px 20px;
    }
    .plan-p {
        padding: 20px 100px;
    }
    .plan {
        padding-top: 150px;
    }
    .plan-circle{
        display: none;
    }
    .plan .row {
        position: unset;
    }
    .pricing-plan {
        padding: 100px 0px;
    }
    .gallery-p {
        padding: 20px 120px;
    }
    .gallery {
        padding-top: 100px;
    }
    .gallery h2{
        padding: 0px 100px;
    }
    .Companies img{
        width: 100%;
        position: static;
    }
    .companies-circle{
        display: none;
    }
    .Companies {
        margin: 100px 0px 0px 0px;
    }
    .customer-bg {
        left: -7%;
    }
    .customers-page .customer-bg {
        left: -14%;
    }
    .customer {
        padding: 30px 30px 30px 100px;
    }
    .news .col-lg-8 .col-lg-5 {
        width: 45.666667%;
    }
    .col-lg-7 {
        width: 56.333333%;
    }
    .news-card2 .news-text {
         padding: 25px 25px 25px 25px;
    }
    .Subscribe {
        width: 80%;
    }
    .banner .Subscribe {
        width: 50%;
    }
    .banner .Subscribe input {
        width: 65%;
    }
    .about-image1 img{
        width: 84%;
    }
    .about-image1 figure {
        background-size: 84%;
    }
    .about-image1 {
        top: 4%;
    }
    .about-circle{
        width: 220px;
        height: 220px;
        margin-left: 15px;
    }
    .ask-question button {
        width: 28%;
    }
    .ask-question .check-box p {
        padding: 0px 140px 0px 20px;
    }
    .ask-question .ask-p {
        padding: 20px 120px;
    }
    .ask-question h2 {
        padding: 0px 100px;
    }
}    

@media all and (max-width: 991px){
     /* global grid setting */
    .container{
        max-width: 900px;
    }
    /* ======== index.html ======== */
    h1{
        font-size: 54px;
        line-height: 60px;
    }
    .open-aside{
        display: block;
    }
    .join-btn{
        display: none;
    }
    .bg-circle{
        width: 400px;
        height: 400px;
        border: 40px solid rgba(34, 176, 252, 0.05);
    }
    .nav-logo img{
        width: 80px;
    }
    .hero-image2 {
        margin-top: 87%;
    }
    .hero-circle {
        margin-left: 0px;
    }
    .hero-image1 figure {
       background-position: left;
        justify-content: start;
        margin-top: 30px;
    }
    .hero-facility {
        top: 18%;
    }
    .Community {
        display: none;
    }
    .Amenities .row .col-lg-3{
        width: 45%;
    }
    .Amenities .row{
        gap: 20px;
    }
    .Amenities{
        padding: 100px 0px;
    }
    .work-image1 figure img {
        width: 95%;
    }
    .work-image1 figure {
        background-size: 95%;
    }
    .work-image1 figure {
        left: -23px;
    }
    .work-circle {
        width: 240px;
        height: 240px;
        right: 3%;
    }
    .ture {
        padding: 10px 10px 10px 10px;
        width: 240px;
    }
    .ture a{
        padding: 10px 20px;
    }
    .spaces-card2{
        left: 12%;  
    }
    .spaces-circle2{
        bottom: -24%;
        left: 23%;
    }
    .spaces-card1{
        left: -12%;
    }
    .spaces-circle1{
        right: 0%;
    }
    .spaces-p{
        padding: 15px 120px 25px 120px;
    } 
    .spaces h2{
        padding: 0px 120px;
    }
    .customers-main-p{
        padding: 0px 70px 50px 70px;
    }
    .customers .row{
       gap: 30px;
    }
    .customers .col-lg-6{
       width: 75%;
    }
    .news-p {
        padding: 20px 70px 30px 70px;
    }
    .news-card2 .news-text{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .news .row{
        margin-right: 0px;
        margin-left: 0px;
    }
    .news .col-lg-4{
        margin-bottom: 30px;
    }
    .news h2{
        padding: 0px 170px 0px 170px;
    }
    .Subscribe-us h2{
        padding: 0px 70px 30px 70px;
    }
    .Subscribe input{
        width: 76%;
    }
    .banner .Subscribe input{
        width: 63%;
    }
    /* ======== 404.html ======== */
    .page-404 h2{
        padding: 15px 100px 0px 100px;
     }
     .page-404 .m-0{
         padding: 20px 100px 30px 100px;
     
     }
}

@media all and (max-width: 768px){
    /* ===== Global Style ===== */
    h1{
        font-size: 40px;
        font-weight: 600;
        line-height: 46px;
    }
    h2{
        font-size: 34px;
        font-weight: 600;
        line-height: 40px;
    }
    h3{
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
    }
    h4{
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }
    h5{
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }
    h6{
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    button{
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        padding: 10px 35px;
    }
    a{
        font-size: 16px ;
        font-weight: 500 ;
        line-height: 22px;
    }
    .h6-color{
        padding-bottom: 15px;
    }
    .hover1{
        padding: 8px 30px;  
    }
    .hover2{
        padding: 8px 30px;  
    }
    #Succes-box{
        font-size: 30px;
    }
    #Succes-box2{
        font-size: 30px;
    }
    #Succes-box3{
        font-size: 30px;
    }
    /* ======== index.html ======== */
    .bg-circle {
        width: 330px;
        height: 330px;
        border: 30px solid rgba(34, 176, 252, 0.05);
    }
    .hero{
        /* padding: 70px 0px; */
        padding-top: 0px;
    }
    .hero-p {
        padding: 10px 10px 15px 0px;
    }
    .hero-image2 {
        margin-top: 87%;
    }
    /* .hero-facility div {
       
        gap: 12px !important;
    } */
    .Community {
        top: 76%;
        padding: 8px 15px;
        display: block;
    }
    .hero-circle {
        width: 170px;
        height: 170px;
    }
    .logo_ipsum_slider img{
        width: 140px;
    }
    .logo_ipsum_slider {
        padding: 15px 0 10px 0;
    }
    .ispsum-logo hr{
        margin-bottom: 0px;
    }
    .ispsum-logo h4 {
        color: #1B1117;
        margin-top: 15px;
    }
    .Amenities .row .col-lg-3{
        width: 46%;
    }
    .Amenities .row{
        gap: 25px;
    }
    .Amenities{
        padding: 70px 0px;
    }
    .Amenities h2{
        padding: 0px 50px;
    }
    .amenities-p{
        padding: 15px 50px;
    }
    .work-image1 figure img {
        width: 98%;
    }
    .work-image1 figure {
        background-size: 98%;
    }
    .work-image1 figure {
        left: -12px;
    }
    .work-circle {
        width: 240px;
        height: 240px;
        right: 3%;
    }
    .ture {
        padding: 10px 10px 10px 10px;
    }
    .workspaces {
        padding: 70px 0px;
    }
    .spaces-card1 {
        padding: 25px 20px;
    }
    .spaces-card1 {
        left: -2%;
        top: 5%;
    }
    .spaces-card2 {
        left: 2%;
        top: 5%;
    }
    .spaces-circle2 {
        bottom: -23%;
        left: 25%;
    }
    .spaces-circle1 {
       right: 25%;
        top: -66%;
    }
    .spaces {
        padding: 70px 0px;
    }
    .spaces-p{
        padding: 15px 70px 25px 70px;
    }
    .spaces h2{
        padding: 0px 50px;
    }
    .spaces .p-0{
        margin-bottom: 100px;
    } 
    .spaces .p-1{
        margin-bottom: 150px;
    }
    .contact-email{
        top: -21%;
        right: 2%;
    }
    .find{
        padding: 70px 0px;
    }
    .plan-p {
        padding: 20px 50px;
    }
    .plan {
        padding-top: 240px;
    }
    .page-gallery{
        padding: 70px 0px 0px 0px;
    }
    .pricing-plan {
        padding: 70px 0px;
    }
    .gallery-p {
        padding: 20px 70px;
    }
    .gallery {
        padding-top: 70px;
    }
    .gallery h2{
        padding: 0px 50px;
        word-wrap: break-word;
    }
    .Companies {
        margin: 40px 0px 0px 0px;
        padding: 70px 0px;
    }
    .Companies .col-lg-6{
        justify-content: center;
    }
    .customer-bg {
        left: -10%;
    }
    .customers-main-p {
        padding: 0px 80px 50px 80px;
    }
    .customers {
        padding: 70px 0px;
    }
    .customers-about{
        padding-bottom: 0px;
    }
    .customers h2{
        padding: 0px 0px 15px 0px;
    }
    .pricing-customers{
        padding: 120px 0px 100px 0px;
    }
    .question {
        padding: 70px 0px;
        background: #F6FCFF;
    } 
    .accordion{
        padding: 25px;
    }
    .accordion-button:not(.collapsed)::after{
        background-size: 15px;
    }
    .accordion-button::after{
        background-size: 15px;
    }
    .accordion-button{
        gap: 10px;
    }
    .news-p{
        padding: 20px 50px 30px 50px;
    }
    .news{
        padding: 70px 0px;
    }
    .news h2{
        padding: 0px 50px 0px 50px;
    }
    .Subscribe-us h2{
        padding: 0px 65px 25px 65px;
    }
    .Subscribe{
        width: 83%;
    }
    .Subscribe-us{
        padding-bottom: 70px;
    }
    .Subscribe input{
        width: 74%;
    }
    .banner .Subscribe{
        width: 75%;
    }
    .banner .Subscribe input{
        width: 72%;
    }
    .about-subscribe{
        padding-top: 70px; 
    }
    footer .col-lg-3{
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
   }
    /* ======== about.html ======== */
   .banner {
    padding: 100px 0px;
    }
    .about-image1 img{
        width: 84%;
    }
    .about-image1 figure {
        background-size: 84%;
    }
    .about-image1 {
        top: 10%;
        right: 2%;
    }
    .about-circle{
        width: 180px;
        height: 180px;
        margin-left: 25px;
        margin-top: 40px;
    }
    .about-image2 img {
        width: 55%;
    }
    .about-image2 figure{
        background-size: 55%;
    }
    .hero-about {
        padding-top: 70px;
    }
    /* ======== contact.html ======== */
    .contact-form {
        padding: 70px 0px;
    }
    /* ======== faq.html ======== */
    .ask-question button {
        width: 32%;
    }
    .ask-question .check-box p {
        padding: 0px 70px 0px 20px;
    }
    .ask-question .ask-p {
        padding: 20px 50px;
    }
    .ask-question .gap-5{
        gap: 20px !important;
    }
    .ask-question h2 {
        padding: 0px 50px;
    }
    .ask-question {
        padding: 70px 0px;
    }
    /* ======== findSpace.html ======== */
    .findSpace{
        padding: 70px 0px;
    }
    /* ======== coming.html ======== */
    .coming h2{
    padding: 15px 50px 0px 50px;
    }
    .coming .m-0{
         padding: 20px 50px 30px 50px;
    }
    .coming .countdown{
         gap: 15px;
    }
    .coming {
         padding: 70px 0px;
    }
    /* ======== 404.html ======== */
    .page-404{
        padding: 70px 0px;
    }
    .page-404 h2{
       padding: 15px 50px 0px 50px;
    }
    .page-404 .m-0{
        padding: 15px 50px 20px 50px;
    }
    .page-404 .copyright{
        padding-top: 20px;
    }
    .page-404 .mt-1{
        gap: 20px;
        padding-top: 25px;
    }
}

@media all and (max-width: 767px){
    /* ======== index.html ======== */
    .customers-page .customer-bg {
        left: 32% !important;
    }

    .hero h1 {
        text-align: center;
        padding: 0px 50px;
    }
    .hero-p {
         padding: 10px 20px 15px 20px;
         text-align: center;
    }
    .hero-image2 {
         margin-top: 45%;
    }
    /* .hero-facility div {
         padding: 5px 15px !important;
         width: 211px !important;
         gap: 18px !important;
    } */
    .hero-circle {
         width: 220px;
         height: 220px;
    }
    .Community {
         top: 76%;
         padding: 8px 15px;
    }
    .hero-image1 figure {
        background-position: right;
        justify-content: end;
        margin-top: 30px;
        background-size: 90%;
    }
    .hero-image1 {
        right: 8% ;
    }
    .hero-image1 img {
        width: 90%;
    }
    .hero-circle {
        right: 40%;
    }
    .hero-image2 {
        position: unset;
    }
    .hero-image2 img {
         width: 38%;
    }
    .hero-image2 figure {
         background-size: 38%;
    }
    .Amenities .row .col-lg-3{
        width: 80%;
    }
    .Amenities .row{
        gap: 25px;
    }
    .workspaces h2 {
        text-align: center;
    }
    .workspaces p {
          text-align: center;
          padding: 15px 50px 30px 50px;
    }
    .workspaces .position-relative{
          margin-bottom: 30px;
    }
    .spaces-card1 {
        left: 0%;
        width: 70%;
        float: right;
    }
    .spaces-card2 {
        width: 70%;
        top: -5%;
        left: 0%;
    }
    .spaces-circle1 {
        right: 54%;
        top: -53%;
        width: 200px;
        height: 200px;
    }
    .spaces-circle2 {
        bottom: 49%;
        left: 50%;
        width: 250px;
        height: 250px;
    }
    .spaces .mb-1 {
        margin-top: 70px;
    }
    .spaces .p-1{
        margin-bottom: 0px;
    }
    .spaces .mb-1{
        gap: 35px;
    }
    .spaces .p-0{
        margin-bottom: 80px;
    }
    .find h2{
        text-align: center;
        padding: 0px 30px;
    }
    .find-p {
        padding: 20px 70px;
        text-align: center;
    }
    .contact-email{
        position: static;
        margin-top: 30px;
    }
    .find .col-sm-12 {
        width: 93%;
    }
    .find .col-12 {
        width: 93%;
    }
    .pricing-find {
        margin-bottom: 0px;
    }
    .plan .row{
        gap: 30px;
    }
    .plan{
        padding: 70px 0px;
        background: #F6FCFF;
    }
    .plan-bg{
      display: none;
    }
    .page-gallery{
        padding: 70px 0px 70px 0px;
    }
    .Companies .col-lg-6{
        align-items: center;
        text-align: center;
    }
    .Companies-p {
        padding: 20px 0px 40px 0px;
    }
    .Companies{
        margin: 70px 0px 0px 0px;
    }
    .customer-bg {
        left: 30%;
        top: -15%;
    }
    .customer {
        padding: 80px 30px 30px 30px;
    }
    .slider_customers .slick-list {
            overflow-x: clip !important;
            overflow-y: visible !important;
    }
    .customer-bg {
        left: 36%;
        top: -26%;
    }
    .customer-p{
        text-align: center;
    }
    .customers-main-p {
        padding: 0px 50px 80px 50px;
    }
    .customers .row{
        gap: 100px;
     }
     .pricing-customers{
        padding: 70px 0px 70px 0px;
    }
    .customers-page .row{
        padding-top: 80px;
    }
    .customers-page .customers-main-p {
        padding: 0px 0px 30px 0px;
    }
    .question .question-text{
        align-items: center;
    }
    .news-card2 .news-text {
        padding: 30px;
    }
    
    .news .col-lg-8 .col-lg-5 {
        width: 99.666667%;
    }
    .col-lg-7 {
        width: 99.333333%;
    }
    .Subscribe {
        width: 450px;
    }
    .Subscribe input {
        width: 64%;
    }
    .banner .Subscribe {
        width: 85%;
    }
    .banner .Subscribe input {
        width: 66%;
    }
    .copyright{
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .copyright p {
        padding-right: 0px;
        text-align: center;
    }
    footer .col-lg-3{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 25px;
    }
    footer .col-lg-3 .gap-4{
       align-items: center;
    }
    .spaces-footer{
        margin-top: 0px;
    }
    /* ======== about.html ======== */
    .hero-about .col-lg-6:first-child{
        display: flex;
    }
    .about-image1 img{
        width: 68%;
    }
    .about-image1 figure {
        background-size: 68%;
    }
    .about-image1 {
        position: relative;
        right: 5%;
    }
    .about-circle{
        width: 230px;
        height: 230px;
        margin-left: 26%;
        margin-top: 35px;
    }
    .about-image2 img {
        width: 44%;
    }
    .about-image2 figure{
        background-size: 44%;
    }
    .about-image2 {
       position: unset;
      padding-left: 5%;
       margin-top: 0px;
    }
    /* ======== contact.html ======== */
    /* .contact-form .row{
        gap: 35px;
    } */
    .contact-form .m-0 {
        padding: 15px 100px 7px 0px;
    }
    /* ======== faq.html ======== */
    .ask-question .gap-5{
        flex-direction: column;
    }
    .ask-question .d-flex .d-flex {
        width: 100%;
    }
    .ask-question textarea {
        width: 100%;
    }
    .ask-question button {
        width: 50%;
    }
    .ask-question .check-box input {
        width: 5%;
    }
    /* ======== 404.html ======== */
    .findSpace .row{
        gap: 22px;
    }
    .spaces-card-2 {
        order: 2;
        margin-top: -80px;
      }
    
    .spaces-image {
     order: 1;
    }
    
}

@media all and (max-width: 540px){
    /* ======== index.html ======== */
    .hero-image2 {
        margin-top: 80%;
    }
    .Community {
        top: 88%;
    }
    .hero-circle {
        right: 48%;
    }
    .spaces-circle1 {
        right: 47%;
        top: -62%;
    }
    .spaces-circle2 {
        bottom: 35%;
        left: 43%;
    }
    .slider_customers .slick-slide {
        padding: 0px 0px;
    }
   
}

@media all and (max-width: 480px){
    /* ===== Global Style ===== */
    h1{
        font-size: 34px;
        font-weight: 600;
        line-height: 40px;
    }
    h2{
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
    }
    h3{
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
    }
    h4{
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
    }
    h5{
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
    }
    h6{
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
    }
    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    button{
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        padding: 10px 35px;
    }
    a{
        font-size: 16px ;
        font-weight: 500 ;
        line-height: 22px;
    }
    .hover1{
        padding: 7px 25px;  
    }
    .hover2{
        padding: 7px 25px;  
    }
    /* Form successfully messages */
    #Succes-box{
        font-size: 28px;
        width: 90%;
        right: 5%;
        padding: 80px 10px;
    }
    #Succes-box2{
        font-size: 28px;
        width: 90%;
        right: 5%;
        padding: 80px 10px;
    }
    #Succes-box3{
        font-size: 28px;
        width: 90%;
        right: 5%;
        padding: 80px 10px;
    }
    /* ======== index.html ======== */
    .bg-circle {
        width: 290px;
        height: 290px;
    }
    /* .nav-logo img {
        width: 155px;
    } */
    aside .fa-xmark {
        font-size: 20px;
    }
    .open-aside {
        padding: 8px 14px;
        font-size: 22px;
    }
    /* .hero{
        padding: 50px 0px;
    } */
    .hero-right {
       margin-top: 10px!important;
    }
    .hero h1 {
        padding: 0px 5px;
     }
     .hero-p {
        padding: 10px 0px 15px 0px;
    }
    .hero-facility {
        top: 26%;
    }
    .hero-circle {
        width: 180px;
        height: 180px;
        border: 16px solid #22B0FC;
    }
    .hero-circle {
        right: 50%;
    }
    .hero-image2 {
        margin-top: 80%;
    }
    .Community {
        top: 88%;
        padding: 8px 15px;
    }
    .hero h1 {
        font-size: 42px;
        line-height: 48px;
    }
    .user-rating {
        margin-top: 20px;
    }
    .logo_ipsum_slider img{
        width: 130px;
    }
    .logo_ipsum_slider {
        padding: 10px 0 5px 0;
    }
    .logo_ipsum_slider img {
        width: 120px;
    }
    .logo_ipsum_slider {
        padding: 5px 0 5px 0;
    }
    .Amenities{
        padding: 50px 0px;
    }
    .Amenities h2{
        padding: 0px 0px;
    }
    .amenities-p{
        padding: 15px 0px;
    }
    .Amenities .row .col-lg-3{
        width: 95%;
    }
    .workspaces {
        padding: 70px 0px;
    }
    .workspaces p {
        text-align: center;
        padding: 15px 5px 25px 5px;
    }
    .work-image1 figure img {
        width: 80%;
    }
    .work-image1 figure {
        background-size: 80%;
    }
    .work-circle {
        width: 200px;
        height: 200px;
    }
    .spaces-card1 {
        width: 83%;
    }
    .spaces-card2 {
        width: 83%;
    }
    .spaces-circle1 {
        right: 50%;
        top: -66%;
        width: 180px;
        height: 180px;
    }
    .spaces-circle2 {
        bottom: 34%;
        left: 50%;
        width: 180px;
        height: 180px;
    }
    .spaces {
        padding: 50px 0px;
    }
    .spaces .mb-1 {
        margin-top: 50px;
    }
    .spaces-p{
        padding: 10px 0px 20px 0px;
    }
    .spaces h2{
        padding: 0px 0px;
    }
    .spaces .col-lg-4{
        gap: 25px;
    }
    .find h2{
        padding: 0px 0px;
    }
    .find{
        padding: 50px 0px;
    }
    .find-p {
        padding: 15px 5px;
    }
    .contact-email{
        margin-top: 20px;
    }
    .e-btn {
        width: 100%;
    }
    .plan-p {
        padding: 15px 10px;
    }
    .plan {
        padding: 50px 0px;
    }
    .page-gallery{
        padding: 50px 0px 20px 0px;
    }
    .gallery-p {
        padding: 15px 10px;
    }
    .gallery {
        padding-top: 50px;
    }
    .gallery h2{
        padding: 0px 0px;
    }
    .Companies {
        margin: 50px 0px 0px 0px;
        padding: 50px 0px;
    }
    .Companies-p {
        padding: 10px 0px 25px 0px;
    }
    .customer-bg figure img {
        width: 96px;
    }
    .customer-bg {
        left: 34%;
        top: -26%;
    }
    .customer {
        padding: 65px 25px 25px 25px;
    }
    .customers-main-p {
        padding: 0px 0px 90px 0px;
    }
    .customers {
        padding: 50px 0px;
    }
    .customers-about{
        padding-bottom: 10px;
    }
    .pricing-customers{
        padding: 50px 0px 50px 0px;
    }
    .customers-page .customers-main-p {
        padding: 0px 0px 20px 0px;
    }
    .customers .col-lg-6{
        width: 85%;
    }
    .question .accordion {
        padding: 15px;
    } 
    .question {
        padding: 50px 0px;
        background: #F6FCFF;
    }
    .accordion-button {
        gap: 10px;
    }
    .news-p {
        padding: 15px 0px 25px 0px;
    }
    .news .news-card .news-text {
        padding: 20px;
    }
    .news-card2 .news-text {
        padding: 20px;
    }
    .news {
        padding: 50px 0px;
    }
    .news .container{
        padding-right: 0px;
        padding-left: 0px; 
    }
    .news-p {
        padding: 15px 10px 25px 10px;
    }
    .news h2{
        padding: 0px 0px 0px 0px;
    }
    .Subscribe-us h2 {
        padding: 0px 0px 20px 0px;
    }
    .Subscribe button {
        width: 110px;
        height: 42px;
       padding: 10px 10px;
    }
    .Subscribe {
        width: 340px;
    }
    #footer-sub2 {
        padding: 5px 5px;
    }
    /* ======== about.html ======== */
    .banner .Subscribe input {
        width: 64%;
    }
    .banner .Subscribe{
        width: 340px;
    }
    .banner .gap-3 a {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
    }
    .about-subscribe{
        padding-top: 50px; 
    }
    .banner {
        padding: 70px 0px;
    }
    .breadcrumb .breadcrumb-item a{
        font-size: 16px;
    }
    .breadcrumb .breadcrumb-item{
        font-size: 16px;
    }
    .about-circle {
        width: 180px;
        height: 180px;
        margin-left: 18%;
        margin-top: 30px;
        border: 15px solid #22B0FC;
    }
    .about-image1 img{
        width: 75%;
        padding: 10px;
    }
    .about-image1 figure {
        background-size: 75%;
    }
    .about-image2 {
        position: unset;
        padding-left: 0%;
        margin-top: 0px;
    }
    .about-image1 {
        position: relative;
        right: 0%;
    }
    .about-image2 img {
        padding: 10px;
    }
    .hero-about h2{
        padding: 10px 0px;
    }
    .hero-about h5{
        padding: 10px 0px;
    }
    .hero-about p{
        padding: 10px 0px;
    }
    .hero-about {
        padding-top: 50px;
    }
    /* ======== contact.html ======== */
    .contact-form {
        padding: 50px 0px;
    }    
    .contact-form .row{
        gap: 25px;
    }
    .contact-form .m-0 {
        padding: 15px 10px 7px 0px;
    }
    .Message-form {
        padding: 25px 30px;
    }
    /* ======== findSpace.html ======== */
    .findSpace .row{
        gap: 18px;
    }
    .findSpace{
        padding: 50px 0px;
    }
    /* ======== faq.html ======== */
    .ask-question button {
        width: 80%;
    }
    .ask-question .check-box p {
        padding: 0px 0px 0px 10px;
    }
    .ask-question .ask-p {
        padding: 20px 0px;
    }
    .ask-question h2 {
        padding: 0px 0px;
    }
    .ask-question {
        padding: 50px 0px;
    }
    .ask-question .check-box input {
        width: 6%;
    }
    /* ======== coming.html ======== */
    .coming h2{
        padding: 10px 0px 0px 0px;
    }
    .coming .m-0{
         padding: 15px 0px 20px 0px;
    }
    #countdown ul{
         gap: 10px;
         padding: 50px 0px 10px 0px;
     }
     #countdown li{
         width: 78px;
         height: 68px; 
     }
     #content_2 h2{
        padding-top: 30px;
        color: #1B1117;
        font-size: 22px;
    }
    #countdown li h5{
        font-size: 16px;
    }
    .coming {
         padding: 50px 0px;
    }
    .coming .mt-1 i {
        font-size: 20px;
    }
    /* ======== 404.html ======== */
    .page-404{
        padding: 50px 0px;
    }
    .page-404 h2{
       padding: 15px 0px 0px 0px;
    }
    .page-404 .m-0{
        padding: 15px 0px 20px 0px;
    }
    .page-404 .copyright{
        padding-top: 15px;
    }
    .page-404 .mt-1{
        gap: 15px;
        padding-top: 20px;
    }
    .page-404 .mt-1 i{
        font-size: 18px;
    }
}

@media all and (max-width: 360px){
    /* ======== index.html ======== */
    .bg-circle {
        width: 250px;
        height: 250px;
        border: 25px solid rgba(34, 176, 252, 0.05);
    }
    /* Form successfully messages */
    #Succes-box{
        font-size: 26px;
        padding: 50px 10px;
    }
    #Succes-box2{
        font-size: 26px;
        padding: 50px 10px;
    }
    #Succes-box3{
        font-size: 26px;
        padding: 50px 10px;
    }
    .hero-image1 {
        right: 0%;
    }
    .hero-circle {
        width: 140px;
        height: 140px;
    }
    .hero-image2 {
        margin-top: 90%;
    }
    .hero .gap-3{
        flex-direction: column;
        text-align: center;
    }
    .user-rating {
        margin-top: 15px;
    }
    .user-rating .d-flex {
        padding: 15px 25px 30px 15px;
    }
    /* .hero-facility div {
        padding: 5px 15px !important;
        width: 105px !important;
        gap: 18px !important;
    }*/
    .hero-facility span {
        width: 27px;
        font-size: 16px;
        font-weight: 500;
    } 
    .hero-facility p {
        line-height: 16px;
    }
    .hero-image1 img {
        padding: 20px;
    }
    .logo_ipsum_slider img{
        width: 110px ;
    }
    .ture a {
        padding: 10px 15px;
    }
    .spaces-card1 {
        width: 95%;
        top: -8%;
    }
    .spaces-card2 {
        width: 95%;
        top: 8%;
    }
    .spaces-circle1 {
        right: 4%;
        top: -30%;
    }
    .spaces-circle2 {
        bottom: 77%;
        left: 4%;
    }
    .Spaces-card h4 {
        padding-bottom: 10px;
    }
    .spaces h3 {
        padding-bottom: 10px;
    }
    .contact-email {
        padding: 40px 20px
    }
    .find .col-12 {
        width: 91%;
    } 
    .customer-bg {
        left: 30%;
        top: -18%;
    }
    .customer-bg figure img {
        width: 86px;
    }
    .customer-bg figure {
        padding: 22px;
    }
    .customers-main-p {
        padding: 0px 0px 70px 0px;
    }
    .customers .col-lg-6{
        width: 95%;
    }
    .question .accordion {
        padding: 10px;
    }
    .Subscribe button {
        width: 90px;
        height: 38px;
       padding: 8px 10px;
    }
    .Subscribe {
        width: 280px;
    }
    footer a {
        color: #fff;
        font-size: 14px;
    }
    #footer-sub2 {
        padding: 5px 5px;
    }
    /* ======== about.html ======== */
    .banner .Subscribe input {
        width: 64%;
    }
    .banner .Subscribe{
        width: 278px;
    }
    .about-circle {
        width: 120px;
        height: 120px;
        margin-left: 14%;
        margin-top: 30px;
        border: 10px solid #22B0FC;
    }
    .about-image1 img{
        width: 75%;
        padding: 10px;
    }
    .about-image1 figure {
        background-size: 75%;
    }
    .about-image2 {
       position: unset;
       padding-left: 0%;
       margin-top: 0px;
    }
    .about-image1 {
        position: relative;
        right: 0%;
    }
    .about-image2 img {
        padding: 10px;
    }
    /* ======== faq.html ======== */
    .ask-question .check-box input {
        width: 8%;
    }
    /* ======== contact.html ======== */
    .Message-form {
        padding: 20px 25px;
    }
    /* ======== coming.html ======== */
    #countdown li{
        width: 60px;
        height: 60px;  
    }
    #countdown li h3{
        font-size: 16px;
     }
     #countdown li h5{
        font-size: 14px;
     }
    
   

}

@media all and (max-width: 320px){
    /* ======== index.html ======== */
    .workspaces .ture {
        width: 259px;
        padding: 12px 10px 12px 10px;
    }
}